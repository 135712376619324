(<template>
  <shared-pricing class="pt-travel-cost-applied">
    <travel-info class="pt-travel-cost-applied__travel-info" />
    <applied-actions />
  </shared-pricing>
</template>)

<script>
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import AppliedActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/AppliedActions';
  import TravelInfo from '@/components/assignment_components/one_assignment/interactive_info/shared_components/TravelInfo';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'applied-actions': AppliedActions,
      'travel-info': TravelInfo
    }
  };
</script>
