(<template>
  <section class="travel-info">
    <travel-type v-if="!isFlatDistanceAmount"
                 :travel-type-selected="travelTypeSelected"
                 class="travel-info__travel-type" />
    <distance-block class="travel-info__distance-section" />
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import TravelType from '@/components/assignment_components/one_assignment/interactive_info/shared_components/TravelType';
  import DistanceBlock from '@/components/assignment_components/one_assignment/interactive_info/shared_components/DistanceBlock';

  export default {
    components: {
      'travel-type': TravelType,
      'distance-block': DistanceBlock,
    },
    computed: {
      ...mapGetters('AssignmentFlowStore', ['travelCostsPricing']),
      travelTypeSelected() { return this.travelCostsPricing.travelType || ''; },
      isFlatDistanceAmount() { return !helpers.priceTemplates.isNotFlatDistanceAmount(this.travelCostsPricing); }
    }
  };
</script>

<style scoped>
  .travel-info__distance-section {
    margin-bottom: 20px;
  }
</style>
